import React, { ReactNode, createContext, useContext, useState } from 'react';

interface EditBookingContextType {
  isEditingBooking: boolean;
  openEditBooking: () => void;
  closeEditBooking: () => void;
}

const EditBookingContext = createContext<EditBookingContextType | undefined>(
  undefined,
);

export const EditBookingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isEditingBooking, setIsEditingBooking] = useState(false);

  const openEditBooking = () => {
    setIsEditingBooking(true);
  };

  const closeEditBooking = () => {
    setIsEditingBooking(false);
  };

  return (
    <EditBookingContext.Provider
      value={{ isEditingBooking, openEditBooking, closeEditBooking }}
    >
      {children}
    </EditBookingContext.Provider>
  );
};

export const useEditBooking = (): EditBookingContextType => {
  const context = useContext(EditBookingContext);
  if (!context) {
    throw new Error(
      'useEditBooking must be used within an EditBookingProvider',
    );
  }
  return context;
};
