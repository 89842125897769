'use client';

import { useRouter } from '@/i18n/routing';
import { AuthPayload } from '@/types';
import {
  AreaChart,
  GiftIcon,
  LogOutIcon,
  Luggage,
  UserIcon,
} from 'lucide-react';
import { useTranslations } from 'next-intl';

import { UserProfiles } from '@repo/db';
import { Avatar, AvatarFallback, AvatarImage } from '@repo/ui/avatar';
import { Button } from '@repo/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@repo/ui/dropdown-menu';

import { signOutAction } from '../../actions';

interface Props {
  authPayload: AuthPayload;
}

export function ProfileButton({ authPayload }: Props) {
  const t = useTranslations('Profile.ProfileButton');
  const router = useRouter();

  const handleNavigation = (path: string) => {
    router.push(path);
  };

  const handleSignOut = async () => {
    try {
      await signOutAction();
      router.refresh();
    } catch (error) {
      console.error('Sign out failed:', error);
    }
  };

  const menuItems = [
    {
      icon: UserIcon,
      label: 'manageProfile',
      action: () => handleNavigation('/protected/profile'),
    },
    ...(authPayload.role === 'TENANT_ADMIN' || authPayload.role === 'ADMIN'
      ? [
          {
            icon: AreaChart,
            label: 'dashboard',
            action: () => handleNavigation('/protected/dashboard'),
          },
        ]
      : []),
    {
      icon: Luggage,
      label: 'myTrips',
      action: () => handleNavigation('/protected/my-trips'),
    },
    ...(authPayload.role === 'AFFILIATE' || authPayload.role === 'ADMIN'
      ? [
          {
            icon: GiftIcon,
            label: 'loyalty',
            action: () => handleNavigation('/protected/loyalty'),
          },
        ]
      : []),
    {
      icon: LogOutIcon,
      label: 'signOut',
      action: handleSignOut,
      isForm: false,
    },
  ];

  const getInitials = (firstName?: string, lastName?: string) => {
    if (firstName || lastName) {
      const initials = `${firstName ?? ''} ${lastName ?? ''}`
        .trim()
        .split(' ')
        .filter((part) => part.length > 0)
        .map((part) => part[0])
        .join('')
        .toUpperCase();
      return initials || 'U'; // Default to 'U' if initials are empty
    }
    return '';
  };

  const hasValidNames = authPayload.firstName || authPayload.lastName;

  return (
    <div className="flex items-center space-x-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="group h-10 w-10 rounded-full p-0 hover:bg-transparent focus:bg-transparent"
            aria-label={`Profile menu for ${authPayload.firstName ?? ''} ${authPayload.lastName ?? ''}`}
          >
            <Avatar className="h-8 w-8 transition-transform group-hover:scale-110">
              <AvatarImage
                src={authPayload.avatarUrl || undefined}
                alt={`${authPayload.firstName ?? 'User'} ${authPayload.lastName ?? ''}`}
                className="object-cover"
              />
              <AvatarFallback className="flex items-center justify-center bg-primary text-primary-foreground transition-colors group-hover:bg-primary/90 group-focus:bg-primary/90">
                {hasValidNames ? (
                  getInitials(authPayload.firstName, authPayload.lastName)
                ) : (
                  <UserIcon className="h-4 w-4" />
                )}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mt-2 w-56 rounded-md bg-white shadow-lg">
          {menuItems.map((item, index) => (
            <DropdownMenuItem
              key={index}
              className="flex cursor-pointer items-center px-3 py-2 text-sm text-gray-700 transition-colors duration-150 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
              onClick={!item.isForm ? item.action : undefined}
              asChild={item.isForm}
            >
              {item.isForm ? (
                <form action={item.action} className="w-full">
                  <button
                    type="submit"
                    className="flex w-full items-center text-left"
                  >
                    <item.icon className="mr-2 h-4 w-4" />
                    <span>{t(item.label)}</span>
                  </button>
                </form>
              ) : (
                <>
                  <item.icon className="mr-2 h-4 w-4" />
                  <span>{t(item.label)}</span>
                </>
              )}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
