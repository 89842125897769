'use client';

import React, { ReactNode, createContext, useContext, useState } from 'react';

import { useTranslations } from 'next-intl';

import { CouponCode } from '@repo/db';
import { useToast } from '@repo/ui/use-toast';

import { validateAndGetCoupon } from '../actions/couponAction';

interface CouponContextType {
  couponCode: string | null;
  couponDetails: CouponCode | null;
  isLoading: boolean;
  applyCoupon: (code: string) => Promise<void>;
  clearCoupon: () => void;
}

const CouponContext = createContext<CouponContextType | undefined>(undefined);

export function CouponProvider({ children }: { children: ReactNode }) {
  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [couponDetails, setCouponDetails] = useState<CouponCode | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const t = useTranslations('Checkout.CouponCode');

  const applyCoupon = async (code: string) => {
    setIsLoading(true);
    try {
      const { isValid, coupon, error } = await validateAndGetCoupon(code);
      if (error) throw error;

      if (isValid && coupon) {
        setCouponCode(code);
        setCouponDetails(coupon);
        toast({
          title: t('success'),
          description: t('discountApplied'),
          variant: 'success',
        });
      } else {
        toast({
          title: t('invalid'),
          description: t('pleaseCheck'),
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast({
        title: t('error'),
        description: t('tryAgainLater'),
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const clearCoupon = () => {
    setCouponCode(null);
    setCouponDetails(null);
  };

  return (
    <CouponContext.Provider
      value={{ couponCode, couponDetails, isLoading, applyCoupon, clearCoupon }}
    >
      {children}
    </CouponContext.Provider>
  );
}

export function useCoupon() {
  const context = useContext(CouponContext);
  if (context === undefined) {
    throw new Error('useCoupon must be used within a CouponProvider');
  }
  return context;
}
