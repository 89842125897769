import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useSearchParams } from 'next/navigation';

interface AffiliateCodeContextType {
  affiliateCode: string | null;
  setAffiliateCode: (code: string | null) => void;
}

const AffiliateCodeContext = createContext<
  AffiliateCodeContextType | undefined
>(undefined);

interface AffiliateCodeProviderProps {
  children: ReactNode;
}

export const AffiliateCodeProvider: React.FC<AffiliateCodeProviderProps> = ({
  children,
}) => {
  const [affiliateCode, setAffiliateCode] = useState<string | null>(null);
  const searchParams = useSearchParams();

  useEffect(() => {
    // Check localStorage first
    const storedCode = localStorage.getItem('affiliateCode');
    if (storedCode) {
      setAffiliateCode(storedCode);
    } else {
      // If not in localStorage, check URL
      const refFromUrl = searchParams.get('ref');
      if (refFromUrl) {
        setAffiliateCode(refFromUrl);
        localStorage.setItem('affiliateCode', refFromUrl);
      }
    }
  }, [searchParams]);

  const updateAffiliateCode = (code: string | null) => {
    setAffiliateCode(code);
    if (code) {
      localStorage.setItem('affiliateCode', code);
    } else {
      localStorage.removeItem('affiliateCode');
    }
  };

  return (
    <AffiliateCodeContext.Provider
      value={{ affiliateCode, setAffiliateCode: updateAffiliateCode }}
    >
      {children}
    </AffiliateCodeContext.Provider>
  );
};

export const useAffiliateCode = (): AffiliateCodeContextType => {
  const context = useContext(AffiliateCodeContext);
  if (!context) {
    throw new Error(
      'useAffiliateCode must be used within an AffiliateCodeProvider',
    );
  }
  return context;
};
