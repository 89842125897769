'use client';

import React from 'react';

import { useRouter } from '@/i18n/routing';
import { sendGTMEvent } from '@next/third-parties/google';
import { useTranslations } from 'next-intl';

import { Button } from '@repo/ui/button';

const LoginButton = () => {
  const router = useRouter();
  const t = useTranslations('Profile');

  return (
    <Button
      onClick={() => {
        sendGTMEvent('loginButtonClicked');
        router.push(`/login`);
      }}
    >
      {t('login')}
    </Button>
  );
};

export default LoginButton;
