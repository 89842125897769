'use client';

import React, { ReactNode, createContext, useContext, useState } from 'react';

import { LocationContextType } from '@/types/index';

import { HqLocation } from '@repo/db';

const LocationContext = createContext<LocationContextType | undefined>(
  undefined,
);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [location, setLocation] = useState<HqLocation>();

  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = (): LocationContextType => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocation must be used within an LocationProvider');
  }
  return context;
};
