'use client';

import { ReactNode } from 'react';

import { useSearchParams } from 'next/navigation';

import { NuqsAdapter } from 'nuqs/adapters/next/app';

import { Tenant } from '@repo/db';

import { AppProvider } from '../../components/homepage/AppProvider';
import { AffiliateCodeProvider } from './_providers/AffiliateCodeContext';
import { CouponProvider } from './_providers/CouponContext';
import { CurrencyProvider } from './_providers/CurrencyContext';
import { EditBookingProvider } from './_providers/EditBookingContext';
import { LocationProvider } from './_providers/LocationContext';
import { PlaceProvider } from './_providers/PlaceContext';
import { SecurityDepositsProvider } from './_providers/SecurityDepositsContext';
import { SortingProvider } from './_providers/SortingContext';
import { TenantsProvider } from './_providers/TenantContext';

interface Props {
  children: ReactNode;
  initialTenants: Tenant[];
}

export function Providers({ children, initialTenants }: Props) {
  return (
    <AppProvider>
      <NuqsAdapter>
        <PlaceProvider>
          <TenantsProvider initialTenants={initialTenants}>
            <LocationProvider>
              <CurrencyProvider>
                <SecurityDepositsProvider>
                  <SortingProvider>
                    <EditBookingProvider>
                      <AffiliateCodeProvider>
                        <CouponProvider>{children}</CouponProvider>
                      </AffiliateCodeProvider>
                    </EditBookingProvider>
                  </SortingProvider>
                </SecurityDepositsProvider>
              </CurrencyProvider>
            </LocationProvider>
          </TenantsProvider>
        </PlaceProvider>
      </NuqsAdapter>
    </AppProvider>
  );
}
