'use client';

import { useState } from 'react';

import CurrencySwitcher from '@/components/CurrencySwitcher';
import LocaleSwitcher from '@/components/LocaleSwitcher';
import { MenuIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';

import { Button } from '@repo/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@repo/ui/sheet';

const MobileHamburgerMenu = () => {
  const t = useTranslations('Header');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="z-30">
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <Button
            variant="purple"
            size="icon"
            className="md:hidden"
            aria-label="Toggle menu"
          >
            <MenuIcon className="h-6 w-6" />
          </Button>
        </SheetTrigger>
        <SheetHeader className="hidden">
          <SheetTitle>{t('title')}</SheetTitle>
          <SheetDescription>{t('description')}</SheetDescription>
        </SheetHeader>
        <SheetContent className="w-[240px] sm:w-[300px]">
          <div className="flex flex-col space-y-4">
            <CurrencySwitcher />
            <LocaleSwitcher />
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default MobileHamburgerMenu;
