'use client';

import Image from 'next/image';

import carLogo from '@/app/[locale]/Lezgo-car-logo.png';
import { useRouter } from '@/i18n/routing';

import { Button } from '@repo/ui/button';

export default function HomeButton() {
  const router = useRouter();

  const onClick = () => {
    router.push('/');
  };

  return (
    <Button variant={'outline'} onClick={onClick}>
      <div className="flex gap-1">
        <Image
          src={carLogo}
          alt="Car logo"
          sizes="100vw"
          style={{
            width: '24px',
            height: 'auto',
            aspectRatio: '1.34',
          }}
        />
        <div>Lezgo</div>
      </div>
    </Button>
  );
}
