'use client';

import React, {
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { usePathname } from 'next/navigation';

import { User } from '@supabase/supabase-js';

import { createClient } from '@/utils/supabase/client';

// Initialize Supabase client
const supabase = createClient();

interface AppContextProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  logoutCleanup: () => Promise<void>;
  userData: User | null;
  userDataLoaded: boolean;
  loadUserData: () => Promise<void>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children: React.ReactNode;
}

const USERDATA_TTL = 60 * 5; // 5 minutes

export const AppProvider: FunctionComponent<AppProviderProps> = ({
  children,
}) => {
  const pathname = usePathname();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<User | null>(null);
  const [userDataLoaded, setUserDataLoaded] = useState<boolean>(false);
  const [userDataLastLoad, setUserDataLastLoad] = useState<Date>(new Date());

  const logoutCleanup = async () => {
    await supabase.auth.signOut();
    setUserData(null);
    setUserDataLoaded(false);
  };

  const loadUserData = async () => {
    setUserDataLoaded(false);
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUserData(user);
    } catch (error) {
      console.error('Error loading user data:', error);
    } finally {
      setUserDataLoaded(true);
    }
  };

  const loadUserDataFromServer = async () => {
    try {
      await loadUserData();
      setUserDataLastLoad(new Date());
    } catch (error) {
      console.error('Failed to load user data from server:', error);
    }
  };

  // Fires on first load
  useEffect(() => {
    loadUserDataFromServer();
  }, []);

  // Fires on page load
  useEffect(() => {
    loadUserData();

    // Reload user data from server if USERDATA_TTL has expired
    if (userDataLastLoad) {
      const now = new Date();
      const diff = now.getTime() - userDataLastLoad.getTime();
      if (diff > USERDATA_TTL * 1000) {
        loadUserDataFromServer();
      }
    }
  }, [pathname]);

  // Set up auth state listener
  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        loadUserData();
      } else if (event === 'SIGNED_OUT') {
        setUserData(null);
        setUserDataLoaded(false);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading,
        logoutCleanup,
        userData,
        userDataLoaded,
        loadUserData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within AppProvider');
  }
  return context;
};
