'use client';

import React from 'react';

import { useCurrency } from '@/app/[locale]/_providers/CurrencyContext';
import { CurrencyCode } from '@/types';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@repo/ui/select';

const CurrencySwitcher = () => {
  const { currency, setCurrencyCode } = useCurrency();

  return (
    <div>
      <Select defaultValue={currency?.code} onValueChange={setCurrencyCode}>
        <SelectTrigger className="w-[70px]">
          <SelectValue placeholder="Currency" />
        </SelectTrigger>
        <SelectContent>
          {Object.values(CurrencyCode).map((code) => (
            <SelectItem
              key={code}
              value={code}
              className="hover:cursor-pointer"
            >
              {code}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default CurrencySwitcher;
